<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card style="width: 500px; max-width: 70vw">
      <q-form ref="refForm">
        <q-card-section class="row items-center justify-between">
          <h6>Adicionar moedas manualmente</h6>
          <q-btn icon="close" flat round dense @click="closeDialog" />
        </q-card-section>
        <q-card-section class="column items-center justify-between">
          <div class="row col-12 justify-between full-width">
            <div class="row col-7">
              <label class="title-group col-12">{{
                isUser ? "Usuário" : "Grupo"
              }}</label>
              <q-input
                class="col-12"
                bg-color="grey-3"
                color="default-pink"
                dense
                v-model="group.group_name"
                type="text"
                outlined
                readonly
                :rules="[(val) => !!val || 'O campo é obrigatório.']"
              >
                <template v-slot:prepend>
                  <div class="image-container">
                    <q-img class="image-input" :src="userImage" width="100%" />
                  </div>
                </template>
              </q-input>
            </div>
            <div class="row col-4">
              <label class="title-group col-12">Valor</label>
              <q-input
                class="col-12"
                bg-color="grey-3"
                color="default-pink"
                dense
                v-model.number="grupsForm.value"
                type="number"
                outlined
                lazy-rules
                :rules="[(val) => !!val || 'O campo valor é obrigatório.']"
              >
                <template v-slot:prepend>
                  <coinsImg color="var(--q-secundary)" />
                  <!-- <q-img class="image-gray" :src="coins" width="18px"/> -->
                </template>
              </q-input>
            </div>
          </div>
          <div class="row col-12 full-width">
            <label class="title-group col-12">Justificativa</label>
            <q-input
              class="col-12"
              bg-color="grey-3"
              color="default-pink"
              type="textarea"
              v-model="grupsForm.description"
              outlined
              lazy-rules
              :rules="[(val) => !!val || 'O campo descrição é obrigatório.']"
            />
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            text-color="default-pink"
            flat
            label="Cancelar"
            color="primary"
            @click="closeDialog"
            v-close-popup
          />
          <q-btn
            color="default-pink"
            text-color="white"
            no-caps
            unelevated
            label="Adicionar moedas"
            @click="validateFrom"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
//----IMAGES
import groupsImg from "@/assets/icons/menu/dark_icon/groups.svg";
import coins from "@/assets/icons/coins.svg";
import coinsImg from "@/assets/icons/custom/Coins.vue";

//----COMPONENTS
import CardLastCourses from "@/components/profile/cards/CardLastCourses.vue";
import { onMounted, ref, watch } from "vue";

export default {
  name: "DialogPoints",
  components: {
    CardLastCourses,
    coinsImg,
  },
  props: {
    showDialog: Boolean,
    group: Object,
    isUser: {
      type: Boolean,
      default: false,
    },
    userImage: {
      type: String,
      default: groupsImg,
    },
  },
  emits: ["closeDialog", "saveDialog", "update:showDialog"],
  setup(props, {emit}) {
    let grupsForm = ref({
      group: 0,
      value: 0,
      description: "",
    });
    let refForm = ref(null);
    let isShowDialog = ref(props.showDialog);


    function closeDialog() {
      grupsForm.value.description = "";
      grupsForm.value.value = 0;
      grupsForm.value.group = 0
      emit('update:showDialog')
    }


onMounted(()=>{
  grupsForm.value.group = props.group.id
})

    watch(
      () => props.showDialog,
      (nV, oV) => {
        isShowDialog.value = nV;
      }
    );

    async function validateFrom() {
      if ( await refForm.value.validate()){
        emit('saveDialog', grupsForm.value)
      }
    }
    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      //----IMAGES
      groupsImg,
      coins,
      grupsForm,
      refForm,
      isShowDialog,

      //----FUNCTION
      validateFrom,
      closeDialog
    };
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  //padding: 4px;
  background: white;
  border-radius: 99px;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-gray {
  filter: grayscale(1);
}
</style>
